import { useQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../Axios/Axios";
import { BsThreeDots } from "react-icons/bs";
import Modal from "react-modal";
import { useState } from "react";
Modal.setAppElement("#root");

export const GetInvoices = () => {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["invoices"],
    queryFn: () =>
      makeRequest
        .get("/payments/get-invoices", { withCredentials: true })
        .then((res) => res.data),
  });

  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => setPopupVisible(!popupVisible);

  const [infos, setInfos] = useState({});

  return (
    <>
      <InvoicesModal
        popupVisible={popupVisible}
        togglePopup={togglePopup}
        infos={infos}
      />
      <div className="dark:text-gray-200 text-stone-700 flex justify-center m-5 h-[calc(100%-2.5rem)]">
        <div className="w-full dark:modal-bg-less p-5 rounded-md overflow-auto scrollbar-hide max-h-screen">
          <h1 className="text-xl font-bold mb-4 border-b border-gray-700 pb-2">
            Faturas
          </h1>

          {isLoading || isFetching ? (
            <p>Carregando...</p>
          ) : error ? (
            <p>Erro. Volte mais tarde.</p>
          ) : (
            <table className="table-fixed w-full border-collapse border">
              <thead className="hidden xl:table-header-group">
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Produto
                </th>
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Fatura
                </th>
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Status
                </th>
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Frequência
                </th>
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Próxima fatura
                </th>
                <th className="border-t border-l border-r border-transparent border-b-transparent px-4 py-2 text-start">
                  Ações
                </th>
              </thead>

              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className="border-t border-l border-r border-transparent"
                  >
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-0 xl:border-b p-3">
                      {item.product}
                    </td>
                    <td
                      className={`block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-0 xl:border-b p-3`}
                    >
                      <div
                        className={`rounded-md p-1 ${
                          item.status === "active"
                            ? "bg-green-400 text-green-900 font-semibold text-center"
                            : item.status === "canceled"
                            ? "bg-red-400 text-red-900 font-semibold text-center"
                            : "bg-yellow-400 text-yellow-900 font-semibold text-center"
                        }`}
                      >
                        {item.status === "active"
                          ? "Paga"
                          : item.status === "canceled"
                          ? "Anulada"
                          : "Falhou"}
                      </div>
                    </td>
                    <td
                      className={`block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-0 xl:border-b p-3`}
                    >
                      <div
                        className={`rounded-md p-1 ${
                          item.cancel_at_period_end === true
                            ? "bg-red-400 text-red-900 font-semibold text-center"
                            : item.cancel_at_period_end === false
                            ? "bg-green-400 text-green-900 font-semibold text-center"
                            : "bg-yellow-400 text-yellow-900 font-semibold text-center"
                        }`}
                      >
                        {item.cancel_at_period_end === true
                          ? "Cancelado"
                          : item.cancel_at_period_end === false
                          ? "Ativo"
                          : "Falhou"}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-0 xl:border-b p-3">
                      {item.frequency}
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-0 xl:border-b p-3">
                      {item.next_invoice_date || "Sem faturas futuras"}
                    </td>
                    <td
                      className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b-gray-700 border-b p-3"
                      onClick={() => {
                        togglePopup();
                        setInfos({ subscriptionId: item.subscriptionId });
                      }}
                    >
                      <div className="flex justify-center dark:bg-slate-900 dark:hover:bg-slate-800 p-2 rounded-md cursor-pointer">
                        <BsThreeDots />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

const InvoicesModal = ({ popupVisible, togglePopup, infos }) => {
  const queryClient = useQueryClient();

  const CancelSubscription = async () => {
    try {
      await makeRequest.post(
        "/payments/cancel-subscription",
        { subscriptionId: infos.subscriptionId },
        {
          withCredentials: true,
        }
      );

      await queryClient.invalidateQueries(["invoices"]);
    } catch (err) {
      console.error("Erro ao cancelar a assinatura:", err);
    }
  };

  return (
    <>
      <Modal
        isOpen={popupVisible}
        onRequestClose={togglePopup}
        contentLabel="Conta Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less rounded-lg p-5 w-80 shadow-lg text-center outline-none"
      >
        <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
          Ações de controle
        </h2>
        <p className="dark:text-white text-gray-600">Id da assinatura</p>

        <div className="flex flex-col dark:text-white text-gray-600">
          <span>{infos.subscriptionId}</span>
          {/* <span>BBB</span> */}
        </div>

        <div className="flex gap-5">
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            onClick={togglePopup}
          >
            Fechar
          </button>
        </div>
        <button
          className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 w-full"
          onClick={() => {
            CancelSubscription();
            togglePopup();
          }}
        >
          Cancelar assinatura
        </button>
      </Modal>
    </>
  );
};
