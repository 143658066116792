import PageIllustration from "../../components/Main/page-illustration";
import Hero from "../../components/Main/hero-home";
import Workflows from "../../components/Main/workflows";
import Features from "../../components/Main/features";
import Testimonials from "../../components/Main/testimonials";
import Cta from "../../components/Main/cta";
import Footer from "../../components/Main/ui/footer";
import Header from "../../components/Main/ui/header";
import { useEffect } from "react";

export const Home = () => {
  useEffect(() => {
    document.body.classList.add("body-home");
    document.body.style.backgroundColor = "#030712";
    // document.body.style.position = "relative";
    return () => {
      document.body.classList.remove("body-home");
      document.body.style.backgroundColor = "";
      // document.body.style.position = "";
    };
  }, []);

  return (
    <>
      <Header />
      <PageIllustration />
      <Hero />
      <Workflows />
      <Features />
      <Testimonials />
      <Cta />
      <Footer />
    </>
  );
};
