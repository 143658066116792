import { useState } from "react";
import ReactPlayer from "react-player";
import { IoIosPause } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import { FaVolumeMute } from "react-icons/fa";
import { FaVolumeOff } from "react-icons/fa";

export default function ModalVideo({ video }) {
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleProgress = (state) => {
    setProgress(state.played * 100);
  };

  const togglePlayPause = () => {
    setPlaying(!playing);
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  return (
    <div className="relative">
      <button
        className="w-full sm:h-[340px] md:h-[620px] group relative flex items-center justify-center rounded-2xl focus:outline-none focus-visible:ring focus-visible:ring-indigo-200"
        aria-label="Watch the video"
        data-aos="fade-up"
        data-aos-delay={200}
      >
        <figure
          style={{
            width: progress <= 0 ? "100%" : "",
            height: progress <= 0 ? "100%" : "",
            backgroundImage: "url(images/hero-image-01.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="relative overflow-hidden rounded-2xl"
        >
          <div
            className={`absolute inset-0 bg-gradient-to-br ${
              muted ? "to-gray-900 via-indigo-500/20 from-gray-900 z-10" : ""
            }`}
          />

          <div className="relative w-full h-full">
            <ReactPlayer
              url={video}
              playing={playing}
              muted={muted}
              onProgress={handleProgress}
              width="100%"
              height="100%"
              loop={true}
            />
            <div
              style={{
                width: `${progress}%`,
              }}
              className="absolute bottom-0 left-0 bg-indigo-500 h-3"
            />
          </div>

          <button
            onClick={togglePlayPause}
            className="absolute bottom-0 left-0 m-2 text-white p-2 rounded-full shadow-lg"
          >
            {playing ? <IoIosPause size={22} /> : <IoPlay size={22} />}
          </button>

          <div
            onClick={toggleMute}
            className="p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center space-x-2 before:absolute before:inset-0 before:rounded-full before:bg-gray-950 before:duration-300 group-hover:before:scale-110 z-10"
            style={{ display: muted ? "" : "none" }}
          >
            <div
              className="rounded-full w-8 h-8 flex justify-center items-center relative z-30"
              style={{
                background:
                  "linear-gradient(to bottom, #6366F1, rgba(99, 102, 241, 0.72))",
              }}
            >
              {muted ? (
                <FaVolumeMute className="text-gray-950" size={16} />
              ) : (
                <FaVolumeOff className="text-gray-950" size={16} />
              )}
            </div>

            <span className="relative z-30 text-sm font-medium leading-tight text-gray-300">
              Reproduzir som
            </span>
          </div>
        </figure>
      </button>
    </div>
  );
}
