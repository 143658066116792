import { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { Metrics } from "../../components/Metrics/Metrics";
import { CandleChart } from "../../components/CandleChart/CandleChart";
import { SelectOptions } from "../../components/SelectOptions/SelectOptions";
import { makeRequest } from "../../components/Axios/Axios";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLineChart } from "react-icons/ai";
import { MexcModal } from "../../components/MexcModal/MexcModal";
import { MenuRight } from "../../components/Menu/Menu";

export const Dashboard = () => {
  const [ativo, setAtivo] = useState("BTCUSDT");
  const [dataInicio, setDataInicio] = useState(
    new Date().toISOString().split("T")[0]
  );
  const storedTheme = localStorage.getItem("theme") || "dark";
  const [theme, setTheme] = useState(storedTheme);

  const [options, setOptions] = useState({ series: [] });
  const [metricValues, setMetricValues] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      try {
        await makeRequest.get("users/verify/token", {
          withCredentials: true,
        });
      } catch (err) {
        navigate("/");
      }
    };

    verifyToken();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await makeRequest
          .post(
            `/chart/get-configs`,
            { ativo: ativo, dataInicio: dataInicio, theme: theme },
            { withCredentials: true }
          )
          .then((response) => {
            const { options, metricValues } = response.data.data;

            setOptions(options);
            setMetricValues(metricValues);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();

    setTimeout(() => {
      fetchData();
    }, 1000);

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [ativo, dataInicio, theme, navigate]);

  return (
    <>
      <MexcModal />
      <div className="md:overflow-hidden h-full md:h-screen flex flex-col">
        <div
          className="absolute w-full h-[calc(100%-3.5rem)] z-10 backdrop-blur-md bottom-0 flex items-center justify-center dark:text-white"
          style={{
            display: `${
              Array.isArray(metricValues) && metricValues[0]?.valor !== 0
                ? "none"
                : ""
            }`,
          }}
        >
          <div className="items-center justify-center text-center">
            <div>Ei, parece que você não possui uma assinatura ativa</div>
            <Link
              className="flex text-center justify-center gap-2"
              to="/?planos"
            >
              Clique aqui para adquirir um plano{" "}
              <AiOutlineLineChart size={24} />
            </Link>
          </div>
        </div>
        <Header theme={theme} setTheme={setTheme} />
        <div className="md:overflow-hidden flex h-full">
          <div className="hidden md:block w-60 flex-shrink-0">
            <MenuRight />
          </div>

          <div className="md:overflow-hidden flex flex-col w-full h-full">
            <SelectOptions
              setDataInicio={setDataInicio}
              dataInicio={dataInicio}
              setAtivo={setAtivo}
              ativo={ativo}
              className="flex-shrink-0"
            />
            <div className="md:overflow-hidden flex flex-col md:flex-row flex-grow justify-between h-full">
              <CandleChart
                options={options}
                className="flex-1 h-full md:w-1/2"
              />
              <Metrics
                ativo={ativo}
                metricValues={metricValues}
                className="flex-1 h-full md:w-1/2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
