import { useLocation, useNavigate } from "react-router-dom";
import { LoginSuaConta } from "../../components/Auth/formsLoginConta";
import { CrieSuaConta } from "../../components/Auth/formsCrieSuaConta";
import { ConfirmeSuaConta } from "../../components/Auth/formsConfirmEmail";
import { ReConfirmeSuaConta } from "../../components/Auth/formsReconfirmEmail";
import { ReenviaAtualizarSenha } from "../../components/Auth/formsReenviaAtualizaSenha";
import { AtualizaSenha } from "../../components/Auth/formsAtualizaSenha";
import { VejaConfirmeSenha } from "../../components/Auth/formsVejaConfirmeSenha";
import { makeRequest } from "../../components/Axios/Axios";
import { useEffect } from "react";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Main/ui/header";
import PageIllustration from "../../components/Main/page-illustration";

export const Auth = () => {
  const location = useLocation();

  const renderForm = () => {
    if (location.pathname === "/entrar") {
      return <LoginSuaConta />;
    }
    if (location.pathname === "/cadastrar") {
      return <CrieSuaConta />;
    }
    if (location.pathname === "/confirme-email") {
      return <ConfirmeSuaConta />;
    }
    if (location.pathname === "/reconfirme-email") {
      return <ReConfirmeSuaConta />;
    }
    if (location.pathname === "/resetar-senha") {
      return <ReenviaAtualizarSenha />;
    }
    if (location.pathname === "/atualizar-senha") {
      return <AtualizaSenha />;
    }
    if (location.pathname === "/verifique-email") {
      return <VejaConfirmeSenha />;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await makeRequest.get("users/verify/token", {
          withCredentials: true,
        });

        navigate("/trading");
      } catch (err) {
        console.log(err);
      }
    };
    verifyToken();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("body-homes");
    document.body.style.backgroundColor = "#030712";
    document.body.style.height = "100vh";
    // document.body.style.overflow = "hidden";
    return () => {
      document.body.classList.remove("body-homes");
      document.body.style.backgroundColor = "";
      document.body.style.height = "";
      // document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <Header />
      <PageIllustration multiple />
      {/* <ToastContainer /> */}

      {/* <div class="w-full h-full"> */}
      {/* <div className="sombra1 h-[500px] w-[500px] fixed top-[-175px] left-[-175px] transform -translate-x-1/2 -translate-y-1/2 rounded-full" />

        <div className="sombra2 h-[500px] w-[500px] fixed bottom-[-175px] right-[-175px] transform translate-x-1/2 translate-y-1/2 rounded-full" /> */}

      {renderForm()}
      {/* </div> */}
    </>
  );
};
