import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest } from "../Axios/Axios.jsx";
import InputMask from "react-input-mask";
import { useState } from "react";
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  nome: Yup.string()
    .required("O nome é obrigatório")
    .min(6, "O nome deve ter pelo menos 6 caracteres"),
  email: Yup.string().email("Email inválido").required("O email é obrigatório"),
  senha: Yup.string()
    .required("Senha é obrigatória")
    .matches(
      /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
      "A senha deve conter pelo menos 8 caracteres, incluindo um caractere especial."
    ),
  repeatSenha: Yup.string()
    .required("Repita a senha")
    .oneOf([Yup.ref("senha"), null], "As senhas devem coincidir"),
  cpf: Yup.string()
    .required("O CPF é obrigatório")
    .matches(
      /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
      "CPF inválido. O formato correto é XXX.XXX.XXX-XX"
    )
    .length(
      14,
      "O CPF deve ter exatamente 14 caracteres, incluindo pontos e hífen."
    ),
  tel: Yup.string()
    .required("O telefone é obrigatório")
    .matches(/^\d+$/, "O telefone só pode conter números")
    .min(10, "O telefone deve ter pelo menos 10 dígitos")
    .max(11, "O telefone pode ter no máximo 11 dígitos"),
  endereco: Yup.string()
    .required("Endereço é obrigatório")
    .min(8, "O endereço deve ter pelo menos 8 caracteres")
    .max(8, "O endereço pode ter no máximo 8 caracteres"),
});

export const CrieSuaConta = () => {
  const navigate = useNavigate();
  const [erro, setErros] = useState("Cadastrar");

  const handleSubmit = async (values) => {
    await makeRequest
      .post(
        "/users/create",
        {
          email: values.email,
          nome: values.nome,
          cpf: values.cpf,
          tel: values.tel,
          endereco: values.endereco,
          senha: values.senha,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data.message);
        localStorage.setItem("email", values.email);

        navigate("/confirme-email");
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.status === 400) {
          let n = 5;
          setErros(
            `Este endereço de email já está em uso. Estamos te redirecionando. ${n}`
          );

          setInterval(() => {
            n--;
            setErros(
              `Este endereço de email já está em uso. Estamos te redirecionando. ${n}`
            );
            if (n === 0) {
              navigate("/reconfirme-email");
            }
          }, 1000);
        } else {
          setErros(error.response.data.message);
        }
      });
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-4 sm:px-6">
        <div className="py-5">
          <div className="pb-12 text-center">
            <h1 className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
              Crie uma conta
            </h1>
          </div>

          <Formik
            initialValues={{
              email: "",
              nome: "",
              cpf: "",
              tel: "",
              endereco: "",
              senha: "",
              repeatSenha: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleBlur, handleChange }) => (
              <Form className="mx-auto max-w-[400px]">
                <div className="space-y-5">
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="email"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="form-input w-full"
                      placeholder="Seu email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="nome"
                    >
                      Nome completo <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="nome"
                      name="nome"
                      type="text"
                      className="form-input w-full"
                      placeholder="Seu nome completo"
                    />
                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="cpf"
                    >
                      CPF <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="cpf"
                      name="cpf"
                      type="text"
                      className="form-input w-full"
                      placeholder="Seu CPF"
                      component={InputMask}
                      mask="999.999.999-99"
                      maskChar={null}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <ErrorMessage
                      name="cpf"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="tel"
                    >
                      Telefone <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="tel"
                      name="tel"
                      type="text"
                      className="form-input w-full"
                      component={InputMask}
                      mask="99999999999"
                      maskChar={null}
                      placeholder="Seu telefone"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <ErrorMessage
                      name="tel"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-indigo-200/65"
                      htmlFor="endereco"
                    >
                      Endereço (CEP)<span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="endereco"
                      name="endereco"
                      type="text"
                      className="form-input w-full"
                      placeholder="Seu endereço (CEP)"
                      maxLength="8"
                      pattern="\d{1,8}"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                    />
                    <ErrorMessage
                      name="endereco"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium text-indigo-200/65"
                      htmlFor="senha"
                    >
                      Senha <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="senha"
                      name="senha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Senha (mais de 8 caracteres)"
                    />
                    <ErrorMessage
                      name="senha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-indigo-200/65"
                      htmlFor="repeatSenha"
                    >
                      Repita a senha <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="repeatSenha"
                      name="repeatSenha"
                      type="password"
                      className="form-input w-full"
                      placeholder="Repita sua senha"
                    />
                    <ErrorMessage
                      name="repeatSenha"
                      component="div"
                      className="text-sm text-red-500"
                    />
                  </div>
                </div>
                <div className="mt-6 space-y-5">
                  <button
                    type="submit"
                    className="text-wrap btn w-full bg-gradient-to-t from-indigo-600 to-indigo-500 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Cadastrando..." : erro}
                  </button>
                  <div className="flex items-center gap-3 text-center text-sm italic text-gray-600 before:h-px before:flex-1 before:bg-gradient-to-r before:from-transparent before:via-gray-400/25 after:h-px after:flex-1 after:bg-gradient-to-r after:from-transparent after:via-gray-400/25">
                    ou
                  </div>
                  {/* <button
                    type="button"
                    className="btn relative w-full bg-gradient-to-b from-gray-800 to-gray-800/60 bg-[length:100%_100%] bg-[bottom] text-gray-300 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] hover:bg-[length:100%_150%]"
                  >
                    Entrar com Google
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>

          <div className="mt-6 text-center text-sm text-indigo-200/65">
            Você já tem uma conta?{" "}
            <Link className="font-medium text-indigo-500" to="/entrar">
              Entrar
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
