import { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
// import { Menu } from "../../components/Menu/Menu";
import { makeRequest } from "../../components/Axios/Axios";
import { useNavigate } from "react-router-dom";
import { GetInvoices } from "../../components/Invoices/Invoices";
import { MenuRight } from "../../components/Menu/Menu";

export const Invoices = () => {
  const storedTheme = localStorage.getItem("theme") || "dark";
  const [theme, setTheme] = useState(storedTheme);

  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      try {
        await makeRequest.get("users/verify/token", {
          withCredentials: true,
        });
      } catch (err) {
        navigate("/");
      }
    };

    verifyToken();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("body-homes");
    if (theme === "dark") {
      document.body.style.backgroundColor = "#030712";
    }

    // document.body.style.overflowY = "hidden";
    return () => {
      document.body.classList.remove("body-homes");
      document.body.style.backgroundColor = "";
      // document.body.style.overflowY = "";
    };
  }, [theme]);

  return (
    <>
      <div className="h-screen flex flex-col">
        <Header theme={theme} setTheme={setTheme} className="flex-shrink-0" />
        <div className="flex flex-grow">
          <div className="hidden md:block w-60 flex-shrink-0 h-full">
            <MenuRight />
          </div>
          <div className="flex-grow overflow-hidden">
            <GetInvoices />
          </div>
        </div>
      </div>
    </>
  );
};
