import { useEffect, useState } from "react";
import Modal from "react-modal";
import { makeRequest } from "../Axios/Axios";
Modal.setAppElement("#root");

export const Modals = ({ togglePopup, popupVisible }) => {
  const [data, setData] = useState({});
  const accountInfos = () => {
    makeRequest
      .get("/users/get/data", { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    accountInfos();
  }, []);

  const date = new Date(data.data);

  const [inputState, setInputState] = useState(false);

  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    setMessage("Carregando...");
    e.preventDefault();

    const form = e.target;
    const senhaAntiga = form.elements.senhaAntiga.value;
    const senhaNova = form.elements.senhaNova.value;

    try {
      const res = await makeRequest.put(
        "/users/token/password/update",
        { senha_nova: senhaNova, senha_antiga: senhaAntiga },
        { withCredentials: true }
      );
      console.log(res);
      setMessage(res.data);

      setTimeout(() => {
        setMessage("");
      }, 2500);
    } catch (err) {
      console.error(err.response.data.message);
      setMessage(err.response?.data?.message || "Erro desconhecido");

      setTimeout(() => {
        setMessage("");
      }, 2500);
    }
  };

  return (
    <Modal
      isOpen={popupVisible}
      onRequestClose={() => {
        togglePopup();
        setInputState(false);
      }}
      contentLabel="Conta Modal"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="bg-white dark:modal-bg-less rounded-lg p-5 w-80 shadow-lg text-center outline-none"
    >
      <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
        Detalhes da Conta
      </h2>
      <p className="dark:text-white text-gray-600">
        Aqui estão as informações da sua conta
      </p>

      <div className="flex flex-col dark:text-white text-gray-600">
        <span>{data.email}</span>
        <span>
          {`Criada em: ${String(date.getDate()).padStart(2, "0")}/${String(
            date.getMonth() + 1
          ).padStart(2, "0")}/${date.getFullYear()}`}
        </span>
      </div>

      <div className="flex gap-5">
        <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
          onClick={() => {
            togglePopup();
            setInputState(false);
          }}
        >
          Fechar
        </button>
      </div>

      {inputState ? (
        <>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
              setInputState(true);
            }}
          >
            <input
              className="mt-4 modal-bg-less text-white py-2 px-4 rounde w-full border-gray-600 border rounded-md focus:outline-blue-500 outline-none transition-all duration-[100ms] ease"
              type="password"
              placeholder="Senha antiga"
              name="senhaAntiga"
            />
            <input
              className="mt-4 modal-bg-less text-white py-2 px-4 rounde w-full border-gray-600 border rounded-md focus:outline-blue-500 outline-none transition-all duration-[100ms] ease"
              type="password"
              placeholder="Senha nova"
              name="senhaNova"
            />
            <input
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
              type="submit"
              value={message === "" ? "Alterar dados" : message}
            />
          </form>
        </>
      ) : (
        <>
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            onClick={() => {
              setInputState(true);
            }}
          >
            Alterar dados
          </button>
        </>
      )}
    </Modal>
  );
};
