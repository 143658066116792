import React, { useEffect, useState } from "react";
import useMasonry from "../utils/useMasonry";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { Element, scroller } from "react-scroll";
import { FaStar } from "react-icons/fa";

export default function Testimonials() {
  const masonryContainer = useMasonry();
  const [category, setCategory] = useState(1);

  const TestimonialImg01 = "images/testimonial-01.jpg";
  const TestimonialImg02 = "images/testimonial-02.jpg";
  const TestimonialImg03 = "images/testimonial-03.jpg";
  const TestimonialImg04 = "images/testimonial-04.jpg";
  const TestimonialImg05 = "images/testimonial-05.jpg";
  const TestimonialImg06 = "images/testimonial-06.jpg";
  const TestimonialImg07 = "images/testimonial-07.jpg";
  const TestimonialImg08 = "images/testimonial-08.jpg";
  const TestimonialImg09 = "images/testimonial-09.jpg";

  const ClientImg01 = "images/price-mensal-basic.svg";
  const ClientImg02 = "images/price-trimestral-basic.svg";
  const ClientImg03 = "images/price-semestral-basic.svg";
  const ClientImg04 = "images/price-mensal-pro.svg";
  const ClientImg05 = "images/price-trimestral-pro.svg";
  const ClientImg06 = "images/price-semestral-pro.svg";
  const ClientImg07 = "images/client-logo-07.svg";
  const ClientImg08 = "images/client-logo-08.svg";
  const ClientImg09 = "images/client-logo-09.svg";

  const testimonials = [
    {
      desc: "CoinVision Basic - Mensal",
      img: TestimonialImg01,
      clientImg: ClientImg01,
      price: "29",
      cent: "99",
      temp: "",
      link: "/payment-page?plano=mensal-basic",
      name: "Adquirir",
      company: "CoinVision Basic - Mensal",
      content:
        "Com o plano mensal basic você tem acesso a funcionalidades incríveis dentro da plataforma, você pode analisar um dos melhores indicadores do mercado cripto tendo um insight personalizado e exclusivo para suas operações, adquira hoje o plano mensal.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: false },
        { item: "Diversas moedas para visualização", is: false },
        { item: "Top 20 moedas em alta 🔥", is: false },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 2],
    },
    {
      desc: "CoinVision Basic - Trimestral",
      img: TestimonialImg02,
      clientImg: ClientImg02,
      price: "26",
      cent: "67",
      temp: "por mês",
      name: "Adquirir",
      link: "/payment-page?plano=trimestral-basic",
      company: "CoinVision Basic - Trimestral",
      content:
        "O plano trimestral basic te dá acesso as mesmas ferramentas que o mensal basic, porém, com um desconto esclusivo de fidelidade feito pela plataforma, aproveite ainda hoje.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: false },
        { item: "Diversas moedas para visualização", is: false },
        { item: "Top 20 moedas em alta 🔥", is: false },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 2],
    },
    {
      desc: "CoinVision Basic - Semestral",
      img: TestimonialImg03,
      clientImg: ClientImg03,
      price: "24",
      cent: "99",
      temp: "por mês",
      name: "Adquirir",
      link: "/payment-page?plano=semestral-basic",
      company: "CoinVision Basic - Semestral",
      content:
        "O plano trimestral basic te dá acesso as mesmas ferramentas que o trimestral basic, porém, com um desconto esclusivo e ainda maior de fidelidade feito pela plataforma, aproveite ainda hoje e com ele concorra chances imperdíveis.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: false },
        { item: "Diversas moedas para visualização", is: false },
        { item: "Top 20 moedas em alta 🔥", is: false },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 2],
    },
    {
      desc: "CoinVision Pro - Mensal",
      img: TestimonialImg04,
      clientImg: ClientImg04,
      price: "44",
      cent: "99",
      temp: "",
      name: "Adquirir",
      link: "/payment-page?plano=mensal-pro",
      company: "CoinVision Pro - Mensal",
      content:
        "The quality of the content generated by this AI tool is outstanding. It has taken our content marketing to new heights, allowing us to publish more frequently without compromising on quality. Highly recommended for anyone.",

      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 3],
    },
    {
      desc: "CoinVision Pro - Trimestral",
      img: TestimonialImg05,
      clientImg: ClientImg05,
      price: "39",
      cent: "99",
      temp: "por mês",
      name: "Adquirir",
      link: "/payment-page?plano=trimestral-pro",
      company: "CoinVision Pro - Trimestral",
      content:
        "The AI-driven content tool has been a lifesaver for my marketing agency. We can now produce high-quality content for multiple clients quickly and efficiently. It's an invaluable asset to our team.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 3],
    },
    {
      desc: "CoinVision Pro - Semestral",
      img: TestimonialImg06,
      clientImg: ClientImg06,
      price: "38",
      cent: "34",
      temp: "por mês",
      name: "Adquirir",
      link: "/payment-page?plano=semestral-pro",
      company: "CoinVision Pro - Semestral",
      content:
        "I'm amazed at how well this AI-driven content tool performs. It's incredibly versatile and can generate content for blogs, social media, and even product descriptions effortlessly. It's fantastic!",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: false },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: false,
        },
      ],
      categories: [1, 3],
    },
    {
      desc: "CoinVision Advanced - Mensal",
      img: TestimonialImg07,
      clientImg: ClientImg07,
      price: "59",
      cent: "99",
      temp: "",
      link: "/payment-page?plano=mensal-advanced",
      name: "Adquirir",
      company: "CoinVision Advanced - Mensal",
      content:
        "I was blown away by how easy it was to create my content using this tool! Within a few hours, I had a professional-looking platform up and running, and my client could not believe it.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: true },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: "especial",
        },
      ],
      categories: [1, 4],
    },
    {
      desc: "CoinVision Advanced - Trimestral",
      img: TestimonialImg08,
      clientImg: ClientImg08,
      price: "56",
      cent: "67",
      temp: "por mês",
      link: "/payment-page?plano=trimestral-advanced",
      name: "Adquirir",
      company: "CoinVision Advanced - Trimestral",
      content:
        "I've never been a fan of complicated website AI tools, which is why Open PRO is perfect for me. Its minimalist design and simple functionality make staying organized feel like second nature.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: true },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: "especial",
        },
      ],
      categories: [1, 4],
    },
    {
      desc: "CoinVision Advanced - Semestral",
      img: TestimonialImg09,
      clientImg: ClientImg09,
      price: "54",
      cent: "99",
      temp: "por mês",
      link: "/payment-page?plano=semestral-advanced",
      name: "Adquirir",
      company: "CoinVision Advanced - Semestral",
      content:
        "I've never been one for coding, so finding an AI tool that doesn't require any technical skills was a dream come true. This tool exceeded my expectations, and I'm proud to show off my new stuff to friends.",
      contents: [
        { item: "Suportes e Resistências para Bitcoin", is: true },
        { item: "Linhas plotadas no gráfico", is: true },
        { item: "Distâncias médias plotadas no gráfico", is: true },
        { item: "Diversas moedas para visualização", is: true },
        { item: "Top 20 moedas em alta 🔥", is: true },
        { item: "Sinais especiais 🔥", is: true },
        {
          item: "Fundo de investimento exclusivo 🔥",
          is: "especial",
        },
      ],
      categories: [1, 4],
    },
  ];

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planos = queryParams.get("planos");

  const scrollToElement = () => {
    scroller.scrollTo("meuComponente", {
      duration: 1500,
      delay: 2,
      smooth: "easeInOutQuart",
    });
  };

  useEffect(() => {
    if (planos === "") {
      scrollToElement();
    }
  }, [planos]);

  return (
    <>
      <Element name="meuComponente">
        {/* <Payments /> */}
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="border-t py-12 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.400/.25),transparent)1] md:py-20">
            {/* Section header */}
            <div className="mx-auto max-w-3xl pb-12 text-center">
              <h2 className="animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text pb-4 font-nacelle text-3xl font-semibold text-transparent md:text-4xl">
                Escolha o plano que melhor se encaixa
              </h2>
              <p className="text-lg text-indigo-200/65">
                Nós providenciamos os melhores planos do mercado para que você
                consiga ter a melhor performance do mercado de criptoativos.
              </p>
            </div>

            <div>
              {/* Buttons */}
              <div className="flex justify-center pb-12 max-md:hidden md:pb-16 text-white">
                <div className="relative inline-flex flex-wrap justify-center rounded-[1.25rem] bg-gray-800/40 p-1">
                  {/* Button #1 */}
                  <button
                    className={`flex h-8 flex-1 items-center gap-2.5 whitespace-nowrap rounded-full px-3 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-200 ${
                      category === 1
                        ? "relative bg-gradient-to-b from-gray-900 via-gray-800/60 to-gray-900 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_bottom,theme(colors.indigo.500/0),theme(colors.indigo.500/.5))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]"
                        : "opacity-65 transition-opacity hover:opacity-90"
                    }`}
                    aria-pressed={category === 1}
                    onClick={() => setCategory(1)}
                  >
                    <svg
                      className={`fill-current ${
                        category === 1 ? "text-indigo-500" : "text-gray-600"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height={16}
                    >
                      <path d="M.062 10.003a1 1 0 0 1 1.947.455c-.019.08.01.152.078.19l5.83 3.333c.052.03.115.03.168 0l5.83-3.333a.163.163 0 0 0 .078-.188 1 1 0 0 1 1.947-.459 2.161 2.161 0 0 1-1.032 2.384l-5.83 3.331a2.168 2.168 0 0 1-2.154 0l-5.83-3.331a2.162 2.162 0 0 1-1.032-2.382Zm7.854-7.981-5.83 3.332a.17.17 0 0 0 0 .295l5.828 3.33c.054.031.118.031.17.002l5.83-3.333a.17.17 0 0 0 0-.294L8.085 2.023a.172.172 0 0 0-.17-.001ZM9.076.285l5.83 3.332c1.458.833 1.458 2.935 0 3.768l-5.83 3.333c-.667.38-1.485.38-2.153-.001l-5.83-3.332c-1.457-.833-1.457-2.935 0-3.767L6.925.285a2.173 2.173 0 0 1 2.15 0Z" />
                    </svg>
                    <span>Ver todos</span>
                  </button>
                  {/* Button #2 */}
                  <button
                    className={`flex h-8 flex-1 items-center gap-2.5 whitespace-nowrap rounded-full px-3 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-200 ${
                      category === 2
                        ? "relative bg-gradient-to-b from-gray-900 via-gray-800/60 to-gray-900 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_bottom,theme(colors.indigo.500/0),theme(colors.indigo.500/.5))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]"
                        : "opacity-65 transition-opacity hover:opacity-90"
                    }`}
                    aria-pressed={category === 2}
                    onClick={() => setCategory(2)}
                  >
                    <svg
                      className={`fill-current ${
                        category === 2 ? "text-indigo-500" : "text-gray-600"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height={16}
                    >
                      <path d="M6.5 3.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM9 6.855A3.502 3.502 0 0 0 8 0a3.5 3.5 0 0 0-1 6.855v1.656L5.534 9.65a3.5 3.5 0 1 0 1.229 1.578L8 10.267l1.238.962a3.5 3.5 0 1 0 1.229-1.578L9 8.511V6.855Zm2.303 4.74c.005-.005.01-.01.013-.016l.012-.016a1.5 1.5 0 1 1-.025.032ZM3.5 11A1.497 1.497 0 0 1 5 12.5 1.5 1.5 0 1 1 3.5 11Z" />
                    </svg>
                    <span>Basic</span>
                  </button>
                  {/* Button #3 */}
                  <button
                    className={`flex h-8 flex-1 items-center gap-2.5 whitespace-nowrap rounded-full px-3 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-200 ${
                      category === 3
                        ? "relative bg-gradient-to-b from-gray-900 via-gray-800/60 to-gray-900 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_bottom,theme(colors.indigo.500/0),theme(colors.indigo.500/.5))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]"
                        : "opacity-65 transition-opacity hover:opacity-90"
                    }`}
                    aria-pressed={category === 3}
                    onClick={() => setCategory(3)}
                  >
                    <svg
                      className={`fill-current ${
                        category === 3 ? "text-indigo-500" : "text-gray-600"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height={16}
                    >
                      <path d="M2.428 10c.665-1.815 1.98-3.604 3.44-4.802-.6-1.807-1.443-3.079-2.29-3.18-1.91-.227-2.246 2.04-.174 2.962a1 1 0 1 1-.813 1.827C-1.407 5.028-.589-.491 3.815.032c1.605.191 2.925 1.811 3.79 4.07.979-.427 1.937-.51 2.735-.092.818.429 1.143 1.123 1.294 2.148.015.1.022.149.043.32.542-.537 1.003-.797 1.693-.622.64.162.894.493 1.195 1.147l.018.04a1 1 0 0 1 1.133 1.61c-.46.47-1.12.574-1.744.398a1.661 1.661 0 0 1-.87-.592 2.127 2.127 0 0 1-.224-.349 3.225 3.225 0 0 1-.55.477c-.377.253-.8.368-1.259.267-.993-.218-1.21-.779-1.367-2.05-.027-.22-.033-.262-.046-.353-.067-.452-.144-.617-.244-.67-.225-.118-.665-.013-1.206.278.297 1.243.475 2.587.516 3.941H15a1 1 0 0 1 0 2H8.68l-.025.285c-.173 1.918-.906 3.381-2.654 3.668-1.5.246-3.013-.47-3.677-1.858-.29-.637-.39-1.35-.342-2.095H1a1 1 0 0 1 0-2h1.428Zm2.11 0h2.175a18.602 18.602 0 0 0-.284-2.577c-.205.202-.408.42-.606.654A9.596 9.596 0 0 0 4.537 10Zm2.135 2H3.942c-.032.465.03.888.194 1.25.258.538.89.836 1.54.73.546-.09.888-.772.988-1.875L6.673 12Z" />
                    </svg>
                    <span>Pro</span>
                  </button>
                  {/* Button #4 */}
                  <button
                    className={`flex h-8 flex-1 items-center gap-2.5 whitespace-nowrap rounded-full px-3 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-200 ${
                      category === 4
                        ? "relative bg-gradient-to-b from-gray-900 via-gray-800/60 to-gray-900 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_bottom,theme(colors.indigo.500/0),theme(colors.indigo.500/.5))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]"
                        : "opacity-65 transition-opacity hover:opacity-90"
                    }`}
                    aria-pressed={category === 4}
                    onClick={() => setCategory(4)}
                  >
                    <svg
                      className={`fill-current ${
                        category === 4 ? "text-indigo-500" : "text-gray-600"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height={16}
                    >
                      <path d="M3.757 3.758a6 6 0 0 1 8.485 8.485 5.992 5.992 0 0 1-5.301 1.664 1 1 0 1 0-.351 1.969 8 8 0 1 0-4.247-2.218 1 1 0 0 0 1.415-.001L9.12 8.294v1.827a1 1 0 1 0 2 0v-4.2a.997.997 0 0 0-1-1.042H5.879a1 1 0 1 0 0 2h1.829l-4.599 4.598a6 6 0 0 1 .648-7.719Z" />
                    </svg>
                    <span>Advanced</span>
                  </button>
                  {/* Button #5 */}
                  {/* <button
                    className={`flex h-8 flex-1 items-center gap-2.5 whitespace-nowrap rounded-full px-3 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-200 ${
                      category === 5
                        ? "relative bg-gradient-to-b from-gray-900 via-gray-800/60 to-gray-900 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_bottom,theme(colors.indigo.500/0),theme(colors.indigo.500/.5))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]"
                        : "opacity-65 transition-opacity hover:opacity-90"
                    }`}
                    aria-pressed={category === 5}
                    onClick={() => setCategory(5)}
                  >
                    <svg
                      className={`fill-current ${
                        category === 5 ? "text-indigo-500" : "text-gray-600"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height={16}
                    >
                      <path d="M13.95.879a3 3 0 0 0-4.243 0L1.293 9.293a1 1 0 0 0-.274.51l-1 5a1 1 0 0 0 1.177 1.177l5-1a1 1 0 0 0 .511-.273l1.16-1.16a1 1 0 0 0-1.414-1.414l-.946.946-3.232.646.646-3.232 8.2-8.2a1 1 0 0 1 1.414 0l1.172 1.172a1 1 0 0 1 0 1.414l-.55.549a1 1 0 0 0 1.415 1.414l.55-.55a3 3 0 0 0 0-4.241L13.948.879ZM3.25 4.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm11.474 6.029-1.521-.752-.752-1.521c-.168-.341-.73-.341-.896 0l-.752 1.52-1.521.753a.498.498 0 0 0 0 .896l1.52.752.753 1.52a.5.5 0 0 0 .896 0l.752-1.52 1.52-.752a.498.498 0 0 0 0-.896Z" />
                    </svg>
                    <span>Empresarial</span>
                  </button> */}
                </div>
              </div>

              {/* Cards */}
              <div
                className="mx-auto grid max-w-sm items-start gap-6 sm:max-w-none sm:grid-cols-2 lg:grid-cols-3 cursor-pointer"
                ref={masonryContainer}
              >
                {testimonials.map((testimonial, index) => (
                  <div
                    key={index}
                    className="group"
                    onClick={() => navigate(testimonial.link)}
                  >
                    <Testimonial testimonial={testimonial} category={category}>
                      {/* “{testimonial.content}” */}
                      <ul className="mt-4">
                        {testimonial.contents.map((content, contentIndex) => (
                          <li
                            key={contentIndex}
                            className="text-gray-600 flex items-center gap-1"
                          >
                            {content.is === true ? (
                              <span className="text-green-500">
                                <IoMdCheckmark size={22} />
                              </span>
                            ) : content.is === "especial" ? (
                              <span className="text-yellow-300">
                                <FaStar size={22} />
                              </span>
                            ) : (
                              <span className="text-red-500">
                                <IoCloseSharp size={22} />
                              </span>
                            )}

                            {content.item}
                          </li>
                        ))}
                      </ul>
                    </Testimonial>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
}
// before:content-['“'] after:content-['”']
export function Testimonial({ testimonial, category, children }) {
  return (
    <article
      className={`relative rounded-2xl bg-gradient-to-br from-gray-900/50 via-gray-800/25 to-gray-900/50 p-5 backdrop-blur-sm transition-opacity before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(to_right,theme(colors.gray.800),theme(colors.gray.700),theme(colors.gray.800))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] 
        ${!testimonial.categories.includes(category) ? "opacity-30" : ""}`}
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-indigo-200/65 text-lg">{testimonial.desc}</h1>

        <div className="text-5xl text-indigo-500 font-bold">
          <div className="flex">
            <div>R$ {testimonial.price},</div>
            <div className="text-3xl">{testimonial.cent}</div>
            <div className="text-lg flex items-end text-gray-600 font-normal">
              {testimonial.temp}
            </div>
          </div>
        </div>
        <p className="text-indigo-200/65">{children}</p>
        <div className="flex items-center gap-3">
          <div className="text-sm font-medium text-gray-200">
            <span>{testimonial.name}</span>
            <span className="text-gray-700"> - </span>
            <a
              className="text-indigo-200/65 transition-colors hover:text-indigo-500"
              href="#0"
            >
              {testimonial.company}
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}
