import { CreditCardForm } from "../Payments/PaymentsModal";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { Link, useNavigate } from "react-router-dom";
import { HiMiniBellAlert } from "react-icons/hi2";
import { LiaSignalSolid } from "react-icons/lia";
import { FaRegCreditCard } from "react-icons/fa";
import { FaMountainSun } from "react-icons/fa6";
import { TbBusinessplan } from "react-icons/tb";
import { RiRobot2Line } from "react-icons/ri";
import { makeRequest } from "../Axios/Axios";
import { TbLogout2 } from "react-icons/tb";
import { FaBitcoin } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalLogut = ({ popupVisible, togglePopup }) => {
  const navigate = useNavigate();

  const logout = () => {
    makeRequest
      .get("/users/logout", { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <>
      <div
        className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4 text-white"
        onClick={togglePopup}
      >
        Sair
        <TbLogout2 size={22} />
      </div>

      <Modal
        isOpen={popupVisible}
        onRequestClose={togglePopup}
        contentLabel="Conta Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less rounded-lg p-5 w-80 shadow-lg text-center outline-none"
      >
        <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
          Deseja realmente sair?
        </h2>

        <div className="flex gap-5">
          <button
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 w-full"
            onClick={logout}
          >
            Sair
          </button>
        </div>
      </Modal>
    </>
  );
};

export const ModalMethodPayment = ({ popupVisible, togglePopup }) => {
  return (
    <>
      <div
        className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
        onClick={togglePopup}
      >
        Cartões
        <FaRegCreditCard size={22} />
      </div>
      <Modal
        isOpen={popupVisible}
        onRequestClose={togglePopup}
        contentLabel="Conta Modal"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less rounded-lg p-5 w-96 shadow-lg text-center outline-none"
      >
        <h2 className="text-xl font-bold mb-4 dark:text-white text-gray-600">
          Atualizar informações de pagamento
        </h2>

        <div>
          <CreditCardForm />
        </div>
      </Modal>
    </>
  );
};

export const MenuRight = () => {
  const [modalLogoutVisible, setModalLogoutVisible] = useState(false);
  const toggleModalLogout = () => setModalLogoutVisible(!modalLogoutVisible);

  const [modalMethodPaymentVisible, setModalMethodPaymentVisible] =
    useState(false);
  const toggleModalMethodPayment = () =>
    setModalMethodPaymentVisible(!modalMethodPaymentVisible);

  return (
    <>
      <div className="hidden md:block h-full menu-bg w-60 z-20 transition-all duration-300 ease relative">
        <div className="w-full flex flex-col text-white gap-2 cursor-pointer h-full justify-between overflow-auto custom-scrollbar custom-scrollbar-thumb custom-scrollbar-track">
          <div className="flex flex-col w-full">
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/trading"
            >
              Pontos Diários
              <LiaSignalSolid size={22} />
            </Link>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/signals"
            >
              Sinais de trade
              <HiMiniBellAlert size={22} />
            </Link>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://www.mexc.com/register?inviteCode=mexc-coinvision"
              target="_blank"
            >
              Abra sua conta na MEXC
              <FaMountainSun size={22} />
            </Link>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://url.hk/i/pt/rz8dv"
              target="_blank"
            >
              Obtenha seu cartão Cripto
              <FaBitcoin size={22} />
            </Link>
            <div className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4 text-gray-400">
              Robos (em breve)
              <RiRobot2Line size={22} />
            </div>
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://wa.me/18996209749?text=Tenho interesse no fundo de investimento"
              target="_blank"
            >
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-500" />
                Invista conosco
              </div>

              <TbBusinessplan size={22} />
            </Link>
          </div>
          <div>
            <ModalMethodPayment
              popupVisible={modalMethodPaymentVisible}
              togglePopup={toggleModalMethodPayment}
            />
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/faturas"
            >
              Faturas
              <LiaFileInvoiceSolid size={22} />
            </Link>
            <ModalLogut
              popupVisible={modalLogoutVisible}
              togglePopup={toggleModalLogout}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const MenuTop = ({ state }) => {
  const [modalLogoutVisible, setModalLogoutVisible] = useState(false);
  const toggleModalLogout = () => setModalLogoutVisible(!modalLogoutVisible);

  const [modalMethodPaymentVisible, setModalMethodPaymentVisible] =
    useState(false);
  const toggleModalMethodPayment = () =>
    setModalMethodPaymentVisible(!modalMethodPaymentVisible);

  return (
    <>
      <div
        className={`md:hidden absolute mt-14 h-auto menu-bg w-full z-20 transition-all duration-700 ease-in-out`}
        style={{
          top: state ? "0" : "-100%",
        }}
      >
        <div className="top-0 w-full flex text-white gap-2 cursor-pointer">
          <div className="flex flex-col w-full">
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/trading"
            >
              Pontos Diários
              <LiaSignalSolid size={22} />
            </Link>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/signals"
            >
              Sinais de trade
              <HiMiniBellAlert size={22} />
            </Link>
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://www.mexc.com/register?inviteCode=mexc-coinvision"
              target="_blank"
            >
              Abra sua conta na MEXC
              <FaMountainSun size={22} />
            </Link>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://url.hk/i/pt/rz8dv"
              target="_blank"
            >
              Obtenha seu cartão Cripto
              <FaBitcoin size={22} />
            </Link>

            <div className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4 text-gray-400">
              Robos (em breve)
              <RiRobot2Line size={22} />
            </div>

            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="https://wa.me/18996209749?text=Tenho interesse no fundo de investimento"
              target="_blank"
            >
              <div className="flex items-center gap-2">
                <FaStar className="text-yellow-500" />
                Invista conosco
              </div>
              <TbBusinessplan size={22} />
            </Link>
            <ModalMethodPayment
              popupVisible={modalMethodPaymentVisible}
              togglePopup={toggleModalMethodPayment}
            />
            <Link
              className="flex justify-between items-center hover:menu-item-bg-hover transition-colors duration-300 w-full p-4"
              to="/faturas"
            >
              Faturas
              <LiaFileInvoiceSolid size={22} />
            </Link>
            <ModalLogut
              popupVisible={modalLogoutVisible}
              togglePopup={toggleModalLogout}
            />
          </div>
        </div>
      </div>
    </>
  );
};
