import { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { makeRequest } from "../../components/Axios/Axios";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLineChart } from "react-icons/ai";
import { SignalsTable } from "../../components/Signals/Signals";
import { MenuRight } from "../../components/Menu/Menu";

export const Signals = () => {
  const storedTheme = localStorage.getItem("theme") || "dark";
  const [theme, setTheme] = useState(storedTheme);
  const [display, setDisplay] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      try {
        await makeRequest.get("users/verify/token", {
          withCredentials: true,
        });
      } catch (err) {
        navigate("/");
      }
    };

    verifyToken();
  }, [navigate]);

  const { isLoading, error, data } = display;

  return (
    <>
      <div className="h-screen flex flex-col dark:modal-bg">
        <div
          className="absolute w-full h-[calc(100%-3.5rem)] z-10 backdrop-blur-md bottom-0 flex items-center justify-center dark:text-white"
          style={{
            display: `${
              isLoading
                ? "none"
                : error
                ? ""
                : Array.isArray(data?.data) && data.data.length === 0
                ? ""
                : "none"
            }`,
          }}
        >
          <div className="items-center justify-center text-center">
            <div>
              {isLoading
                ? "Carregando..."
                : error
                ? "Erro. Volte mais tarde..."
                : Array.isArray(data?.data) && data.data.length === 0
                ? "Ei, parece que você não possui uma assinatura advanced ativa"
                : "Erro. Volte mais tarde..."}
            </div>
            <Link
              className="flex text-center justify-center gap-2"
              to="/?planos"
            >
              Clique aqui para adquirir um plano{" "}
              <AiOutlineLineChart size={24} />
            </Link>
          </div>
        </div>
        <Header theme={theme} setTheme={setTheme} />
        <div className="overflow-auto custom-scrollbar custom-scrollbar-thumb custom-scrollbar-track h-full flex">
          <div className="w-60">
            <MenuRight />
          </div>

          <div className="p-5">
            <SignalsTable setDisplay={setDisplay} theme={theme} />
          </div>
        </div>
      </div>
    </>
  );
};
