import { AiOutlineShopping } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { PaymentForm } from "../../components/Payments/PaymentForm";
import { useLocation } from "react-router-dom";
import PageIllustration from "../Main/page-illustration";

export const PaymentFormGroup = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plano = queryParams.get("plano");
  const [price, setPrice] = useState("");
  const [priceId, setPriceId] = useState("");
  const [stamp, setEstamp] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    switch (plano) {
      case "mensal-basic":
        setPrice("R$ 29,99");
        setEstamp("1 mês");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[0]);
        setTitle("PLANO MENSAL BASIC");
        break;
      case "trimestral-basic":
        setPrice("R$ 79,99");
        setEstamp("3 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[1]);
        setTitle("PLANO TRIMESTRAL BASIC");
        break;
      case "semestral-basic":
        setPrice("R$ 149,99");
        setEstamp("6 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[2]);
        setTitle("PLANO SEMESTRAL BASIC");
        break;
      case "mensal-pro":
        setPrice("R$ 44,99");
        setEstamp("1 mês");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[3]);
        setTitle("PLANO MENSAL PRO");
        break;
      case "trimestral-pro":
        setPrice("R$ 119,99");
        setEstamp("3 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[4]);
        setTitle("PLANO TRIMESTRAL PRO");
        break;
      case "semestral-pro":
        setPrice("R$ 229,99");
        setEstamp("6 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[5]);
        setTitle("PLANO SEMESTRAL PRO");
        break;
      case "mensal-advanced":
        setPrice("R$ 59,99");
        setEstamp("1 mês");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[6]);
        setTitle("PLANO MENSAL ADVANCED");
        break;
      case "trimestral-advanced":
        setPrice("R$ 169,99");
        setEstamp("3 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[7]);
        setTitle("PLANO TRIMESTRAL ADVANCED");
        break;
      case "semestral-advanced":
        setPrice("R$ 329,99");
        setEstamp("6 meses");
        setPriceId(JSON.parse(process.env.REACT_APP_PROD_IDS)[8]);
        setTitle("PLANO SEMESTRAL ADVANCED");
        break;
      default:
        setPrice("R$ 0,00");
        setEstamp("Plano inválido");
    }
  }, [plano]);

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="fixed z-10 top-0 left-0 hidden sm:block">
          <PageIllustration multiple />
        </div>

        <div className="hidden bg-[#030712] h-screen w-full relative justify-center items-end sm:flex">
          <div
            className="absolute top-[10%]"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="flex items-center gap-5">
              <div className="rounded-full shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)] w-[28px] h-[28px] flex items-center justify-center bg-white">
                <AiOutlineShopping color="#5e5e5e" />
              </div>
              <li className="list-none text-sm font-semibold text-gray-500">
                COIN VISION LTDA
              </li>
            </div>

            <div className="text-gray-500 font-semibold mt-8">
              Assinar {title}
            </div>
            <div className="flex items-end gap-2">
              <div className="text-gray-50 font-semibold mt-2 from-neutral-900 text-4xl">
                {price}
              </div>
              <div className="flex flex-col text-sm text-gray-500 font-semibold">
                <span>a cada</span>
                <span>{stamp}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex bg-white h-screen md:overflow-auto w-full relative justify-center"
          style={{ boxShadow: "inset 0 6px 8px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="absolute top-[5%] md:top-[5%] w-full">
            <div className="flex bg-white w-full relative justify-center items-end sm:hidden">
              <div className="">
                <div className="flex items-center gap-5">
                  <div className="rounded-full shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)] w-[28px] h-[28px] flex items-center justify-center">
                    <AiOutlineShopping color="#5e5e5e" />
                  </div>
                  <li className="list-none text-sm font-semibold text-gray-700">
                    COIN VISION LTDA
                  </li>
                </div>

                <div className="text-gray-500 font-semibold mt-8">Assinar</div>
                <div className="flex items-end gap-2">
                  <div className="text-gray-800 font-semibold mt-2 from-neutral-900 text-4xl">
                    {price}
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 font-semibold">
                    <span>a cada</span>
                    <span>{stamp}</span>
                  </div>
                </div>
              </div>
            </div>

            <PaymentForm priceId={priceId} />
          </div>
        </div>
      </div>
    </>
  );
};
