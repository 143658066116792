import Logo from "./logo";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export default function Footer() {
  return (
    <footer>
      <div className="relative mx-auto max-w-6xl px-4 sm:px-6 ">
        {/* Footer illustration */}
        <div
          className="pointer-events-none absolute bottom-0 left-1/2 -z-10 -translate-x-1/2"
          aria-hidden="true"
        >
          <img
            className="max-w-none"
            src="images/footer-illustration.svg"
            width={1076}
            height={378}
            alt="Footer illustration"
          />
        </div>
        <div className="grid grid-cols-2 justify-between gap-12 py-8 sm:grid-rows-[auto_auto] md:grid-cols-4 md:grid-rows-[auto_auto] md:py-12 lg:grid-cols-[repeat(4,minmax(0,140px))_1fr] lg:grid-rows-1 xl:gap-20">
          {/* 1st block */}
          <div className="space-y-2 invisible opacity-0 hidden">
            <h3 className="text-sm font-medium text-gray-200">Product</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Integrations
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Pricing &amp; Plans
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Changelog
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Our method
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  User policy
                </a>
              </li>
            </ul>
          </div>
          {/* 2nd block */}
          <div className="space-y-2 invisible opacity-0 hidden">
            <h3 className="text-sm font-medium text-gray-200">Company</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  About us
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Diversity &amp; Inclusion
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Financial statements
                </a>
              </li>
            </ul>
          </div>
          {/* 3rd block */}
          <div className="space-y-2 invisible opacity-0 hidden">
            <h3 className="text-sm font-medium text-gray-200">Resources</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Community
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Terms of service
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Report a vulnerability
                </a>
              </li>
            </ul>
          </div>
          {/* 4th block */}
          <div className="space-y-2 hidden">
            <h3 className="text-sm font-medium text-gray-200">
              Content Library
            </h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Templates
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Tutorials
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Knowledge base
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Learn
                </a>
              </li>
              <li>
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  Cookie manager
                </a>
              </li>
            </ul>
          </div>
          {/* 5th block */}
          <div className="col-span-8 text-center">
            <div className="mb-3">
              <Logo />
            </div>
            <div className="text-sm">
              <p className="mb-3 text-indigo-200/65">
                © CoinVision.com.br
                {/* <span className="text-gray-700"> · </span> */}
                <a
                  className="text-indigo-200/65 transition hover:text-indigo-500"
                  href="https://www.instagram.com/thewalkingoak"
                >
                  {/* Terms */}
                </a>
              </p>
              <ul className="inline-flex gap-2">
                <li>
                  <a
                    className="flex items-center justify-center text-indigo-500 transition hover:text-indigo-400"
                    href="https://x.com/coinvision_ofc"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <FaXTwitter size={22} />
                  </a>
                </li>
                <li>
                  <a
                    className="flex items-center justify-center text-indigo-500 transition hover:text-indigo-400"
                    href="mailto:contato@coinvision.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Email"
                  >
                    <MdEmail size={22} />
                  </a>
                </li>
                <li>
                  <a
                    className="flex items-center justify-center text-indigo-500 transition hover:text-indigo-400"
                    href="https://www.instagram.com/coinvisionoficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <FaInstagram size={22} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
