import { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

export const MexcModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const modalStatus = localStorage.getItem("mexcModal");
    if (modalStatus !== "true") {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem("mexcModal", "true");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Conta Modal"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      className="bg-white dark:modal-bg-less rounded-lg p-5 w-auto shadow-lg text-center outline-none flex flex-col gap-5"
    >
      <h2 className="text-xl font-bold dark:text-white text-gray-600">
        Ei, você tem conta na Mexc?
      </h2>

      <div className="flex flex-col dark:text-white text-gray-600">
        <Link
          to="https://www.mexc.com/register?inviteCode=mexc-coinvision"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://static.mocortech.com/www/static/images/new-home/TDK/og_US.png"
            alt="Mexc"
            className="rounded-md"
          />
        </Link>
      </div>

      <div className="flex gap-5">
        <button
          className=" bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
          onClick={handleClose}
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
};
