import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../Axios/Axios";
import { TablePagination, Typography, TextField } from "@mui/material";
import dayjs from "dayjs";
import Modal from "react-modal";

Modal.setAppElement("#root");

const headCells = [
  { id: "msg", numeric: false, disablePadding: false, label: "Mensagem" },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Data" },
  { id: "signal", numeric: false, disablePadding: false, label: "Sinal" },
  { id: "symbol", numeric: false, disablePadding: true, label: "Moeda" },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Preço de entrada",
  },
  {
    id: "exitPrice",
    numeric: true,
    disablePadding: false,
    label: "Preço de saída",
  },
  {
    id: "takeProfit",
    numeric: false,
    disablePadding: false,
    label: "Take Profit",
  },
  { id: "stopLoss", numeric: false, disablePadding: false, label: "Stop Loss" },
  {
    id: "resultado",
    numeric: false,
    disablePadding: false,
    label: "Resultado",
  },
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <thead className="hidden xl:table-header-group">
      <tr className="border-gray-300">
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            className={`px-4 py-2 ${
              headCell.numeric ? "text-right" : "text-left"
            } font-medium text-gray-700 dark:text-gray-300 text-sm uppercase tracking-wide border border-gray-300`}
          >
            <button
              onClick={createSortHandler(headCell.id)}
              className="flex items-center"
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <span className="ml-2 text-sm">
                  {order === "desc" ? "↓" : "↑"}
                </span>
              )}
            </button>
          </th>
        ))}
      </tr>
    </thead>
  );
}

export const SignalsTable = ({ setDisplay, theme }) => {
  let previousData = null;
  const { isLoading, error, data } = useQuery({
    queryKey: ["signals"],
    queryFn: () =>
      makeRequest
        .get("/signals/get-signals", { withCredentials: true })
        .then((res) => res.data),
    refetchInterval: 3000,
  });

  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [canPlayAudio, setCanPlayAudio] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para o termo de pesquisa

  useEffect(() => {
    const audio = new Audio("songs/notification.mp3");

    if (canPlayAudio && data !== previousData) {
      audio.play();
    }

    audio.onended = () => {
      console.log("Áudio terminado");
    };
  }, [data, previousData, canPlayAudio]);

  const closeModal = () => {
    setModalIsOpen(false);
    setCanPlayAudio(true);
  };

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt"); // Ordenando inicialmente pela data
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setDisplay({ isLoading, error, data });
  }, [isLoading, error, data, setDisplay]);

  if (isLoading)
    return <Typography className="dark:text-white">Carregando...</Typography>;
  if (error)
    return (
      <Typography className="dark:text-white">
        Error. Volte mais tarde...
      </Typography>
    );

  const rows = data.data;

  // Aplicar filtro de busca
  const filteredRows = rows.filter((row) =>
    row.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const visibleRows = filteredRows
    .slice()
    .sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        className="bg-white dark:modal-bg-less p-5 w-80 shadow-lg text-center outline-none dark:text-white rounded-md"
      >
        <h1 className="text-xl font-semibold">Atenção!</h1>
        <p>
          Esta página gera sons. Você pode ouvir uma notificação sonora quando
          houver alterações nos dados.
        </p>
        <button
          onClick={closeModal}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
        >
          Entendo que esta página não dá conselhos sobre investimentos
        </button>
      </Modal>
      <div className="w-full h-full flex flex-col">
        <div className="text-xl font-bold text-gray-900 dark:text-gray-100 ml-1">
          Sinais
        </div>

        <TextField
          label="Filtrar por moeda"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            style: {
              color: theme === "dark" ? "white" : "black",
              border: "none",
            },
          }}
          InputLabelProps={{
            style: {
              color: theme === "dark" ? "white" : "black",
              border: "none",
            },
          }}
          className="dark:bg-gray-800 dark:placeholder-gray-400 text-white rounded-md"
        />
        <div className="bg-white dark:modal-bg-less modal-bg-b-less shadow rounded p-4 overflow-auto custom-scrollbar custom-scrollbar-thumb custom-scrollbar-track h-full">
          <div className="h-full overflow-x-auto flex-1 overflow-y-auto overflow-auto custom-scrollbar custom-scrollbar-thumb custom-scrollbar-track sm:flex md:block">
            <table className="w-full border border-gray-300 table-fixed h-full">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <tbody>
                {visibleRows.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 text-wrap xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Mensagem</div>
                        {row.msg}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Data</div>
                        {dayjs(row.createdAt).format("YYYY/DD/MM HH:mm:ss")}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 xl:border-b border-gray-300">
                      <div className="">
                        <div className="xl:hidden">Sinal</div>

                        <div className="flex sm:flex-nowrap xl:flex-wrap 2xl:flex-nowrap gap-3 w-full">
                          {row.signal.map((signal, index) => (
                            <div
                              key={index}
                              className={`p-2 rounded w-full font-semibold ${
                                signal === "BUY"
                                  ? "bg-green-500 text-white"
                                  : signal === "SELL"
                                  ? "bg-red-500 text-white"
                                  : "bg-blue-500 text-white"
                              }`}
                            >
                              {signal}
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Moeda</div>
                        {row.symbol}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Preço de entrada</div>
                        {row.price}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Preço de saída</div>
                        {row.exitPrice}
                      </div>
                    </td>
                    <td className="h-auto block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 break-words whitespace-pre-wrap xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Take Profit</div>
                        {row.takeProfit.toFixed(8)}
                      </div>
                    </td>
                    <td className="block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none px-4 py-2 text-left text-gray-900 dark:text-gray-100 break-words whitespace-pre-wrap xl:border-b border-gray-300">
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Stop Loss</div>
                        {row.stopLoss.toFixed(8)}
                      </div>
                    </td>
                    <td
                      className={`px-4 py-2 text-left block xl:table-cell before:content-[attr(data-label)] before:font-bold before:uppercase md:before:content-none border-b border-gray-300 ${
                        parseFloat(row.resultado) < 0
                          ? "text-red-500"
                          : parseFloat(row.resultado) > 0
                          ? "text-green-500"
                          : "text-yellow-500"
                      }`}
                    >
                      <div className="flex items-center justify-between w-full">
                        <div className="xl:hidden">Resultado</div>
                        {row.resultado === 0
                          ? "Em aberto..."
                          : row.resultado.toFixed(2) + "%"}
                      </div>
                    </td>
                  </tr>
                ))}

                {/* {emptyRows > 0 && (
                  <tr
                    style={{ height: `${53 * emptyRows}px` }}
                    className="bg-gray-50 border-b border-gray-300"
                  >
                    <td colSpan={4} className="px-4 py-2 text-center" />
                  </tr>
                )} */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="overflow-y-hidden">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Itens por página"
            className="dark:text-white"
          />
        </div>
      </div>
    </>
  );
};
